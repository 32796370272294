<template>
  <div class="mx-auto max-w-7xl main-feature">
    <div class="flex flex-col gap-8 pt-24 pb-40 md:py-72">
      <div
        class="
          flex flex-col
          gap-4
          md:gap-0 md:flex-row
          justify-start
          md:justify-between
        "
      >
        <div class="feature">
          <p class="text-2xl md:text-3xl font-bold max-w-xs pb-2 md:pb-4">
            Vizualize your itinerary on a map
          </p>
          <p class="leading-normal text-sm md:text-xl max-w-sm">
            Organized by day, view your itinerary visually all in one place.
          </p>
        </div>
        <div class="feature relative">
          <img
            src="../assets/features/itinerary_map.svg"
            class="hidden md:block"
          />
          <img
            src="../assets/features/itinerary_map_mobile.png"
            class="md:hidden"
          />
          <div class="absolute md:hidden plane_track_mobile_1">
            <img src="../assets/features/plane_track_mobile_1.svg" />
          </div>
        </div>
      </div>
      <div
        class="
          flex flex-col
          gap-4
          md:gap-0 md:flex-row-reverse
          justify-end
          md:justify-between
          items-end
          md:items-center
        "
      >
        <div class="feature text-right md:text-left">
          <p class="text-2xl md:text-3xl font-bold max-w-xs pb-2 md:pb-4">
            Chat with AI
          </p>
          <p class="leading-normal text-sm md:text-xl max-w-sm">
            Our AI chat functions like ChatGPT - ask anything about your trip
            and update your itinerary
          </p>
        </div>
        <div class="feature relative">
          <img src="../assets/features/ask_ai.svg" class="hidden md:block" />
          <img src="../assets/features/ask_ai_mobile.png" class="md:hidden" />
          <div class="absolute hidden md:block plane_track_1">
            <img src="../assets/features/plane_track_1.svg" />
          </div>
        </div>
      </div>
      <div
        class="
          flex flex-col
          gap-4
          md:gap-0 md:flex-row
          justify-start
          md:justify-between
          items-start
          md:items-center
          feature
        "
      >
        <div class="feature">
          <p class="text-2xl md:text-3xl font-bold max-w-xs pb-2 md:pb-4">
            Book flights, stays and experiences
          </p>
          <p class="leading-normal text-sm md:text-xl max-w-sm">
            With an incredible collection of over 6,000 tours in 136 countries,
            we have partnerships in place so you're all set.
          </p>
        </div>
        <div class="feature relative">
          <img src="../assets/features/bookings.svg" class="hidden md:block" />
          <img src="../assets/features/bookings_mobile.png" class="md:hidden" />
          <div class="absolute hidden md:block plane_track_2">
            <img src="../assets/features/plane_track_2.svg" />
          </div>
          <div class="absolute md:hidden plane_track_mobile_2">
            <img src="../assets/features/plane_track_mobile_2.svg" />
          </div>
        </div>
      </div>
      <div
        class="
          flex flex-col
          md:flex-row-reverse
          justify-end
          md:justify-between
          items-end
          md:items-center md:pt-36 md:pb-20
          gap-4
          md:gap-0
        "
      >
        <div class="feature text-right md:text-left">
          <p
            class="
              hidden
              md:block
              text-2xl
              md:text-3xl
              font-bold
              max-w-xs
              pb-2
              md:pb-4
            "
          >
            Collaborate with friends & family
          </p>
          <p
            class="
              md:hidden
              text-2xl
              md:text-3xl
              font-bold
              max-w-xs
              pb-2
              md:pb-4
            "
          >
            Collaborate with Friends
          </p>
          <p class="leading-normal text-sm md:text-xl max-w-sm">
            Share your itineraries with friends to view, or invite them as
            collaborators to allow them to make edits and incorporate their own
            plans.
          </p>
        </div>
        <div class="feature relative">
          <img
            src="../assets/features/itinerary_collaborate.svg"
            class="hidden md:block"
          />
          <img
            src="../assets/features/itinerary_collaborate_mobile.png"
            class="md:hidden"
          />
          <div class="absolute hidden md:block plane_track_3">
            <img src="../assets/features/plane_track_3.svg" />
          </div>
          <div class="absolute md:hidden plane_track_mobile_3">
            <img src="../assets/features/plane_track_mobile_3.svg" />
          </div>
        </div>
      </div>
      <div
        class="
          flex flex-col
          gap-4
          md:gap-0 md:flex-row
          justify-start
          md:justify-between
          items-start
          md:items-center
        "
      >
        <div class="feature">
          <p class="text-2xl md:text-3xl font-bold max-w-xs pb-2 md:pb-4">
            Discover other itineraries
          </p>
          <p class="leading-normal text-sm md:text-xl max-w-sm">
            Find public itineraries built by other travelers, or verified
            itineraries built by Tern under the
            <a
              href="https://tern.app/discovery"
              target="_blank"
              class="
                underline underline-offset-8
                hover:text-ternBlue
                cursor-pointer
              "
              >Explore section</a
            >.
          </p>
        </div>
        <div class="feature relative">
          <img src="../assets/features/discover.svg" class="hidden md:block" />
          <img src="../assets/features/discover_mobile.png" class="md:hidden" />
          <div class="absolute hidden md:block plane_track_4">
            <img src="../assets/features/plane_track_4.svg" />
          </div>
          <div class="absolute md:hidden plane_track_mobile_4">
            <img src="../assets/features/plane_track_mobile_4.svg" />
          </div>
        </div>
      </div>
      <div
        class="
          flex flex-col
          gap-4
          md:gap-0 md:flex-row-reverse
          justify-end
          md:justify-between
          items-end
          md:items-center
        "
      >
        <div class="feature text-right md:text-left">
          <p class="text-2xl md:text-3xl font-bold max-w-xs pb-2 md:pb-4">
            Shop themes
          </p>
          <p class="leading-normal text-sm md:text-xl max-w-sm">
            Scope out our curated themes like 'Emily in Paris' and 'Ultimate
            Spring Break' in the
            <a
              href="https://tern.app/shop"
              target="_blank"
              class="
                underline underline-offset-8
                hover:text-ternBlue
                cursor-pointer
              "
              >Shop section</a
            >. Keep an eye out for more themes!
          </p>
        </div>
        <div class="feature relative">
          <img src="../assets/features/shop.svg" class="hidden md:block" />
          <img src="../assets/features/shop_mobile.png" class="md:hidden" />
          <div class="absolute hidden md:block plane_track_5">
            <img src="../assets/features/plane_track_5.svg" />
          </div>
          <div class="absolute md:hidden plane_track_mobile_5">
            <img src="../assets/features/plane_track_mobile_5.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeatureSection',
  methods: {
    closeBanner() {
      this.showBanner = false;
    },
  },
};
</script>
<style scoped>
.plane_track_1 {
  z-index: -1;
  top: -145%;
  right: -41%;
}
.plane_track_2 {
  bottom: -97%;
  left: -60%;
  z-index: -1;
}
.plane_track_3 {
  z-index: -1;
  bottom: -165%;
  right: -80%;
}
.plane_track_4 {
  bottom: -30%;
  left: -65%;
  z-index: -1;
}
.plane_track_5 {
  z-index: -1;
  right: -70%;
  bottom: -50%;
}
.plane_track_mobile_1 {
  top: -120%;
  right: -20%;
  z-index: -1;
}

.plane_track_mobile_2 {
  bottom: -150%;
  right: -40%;
  z-index: -1;
}
.plane_track_mobile_3 {
  z-index: -1;
  bottom: -25%;
  right: -13%;
  width: 403px;
  left: -61px;
}
.plane_track_mobile_4 {
  top: -90%;
  right: -40%;
  z-index: -1;
}
.plane_track_mobile_5 {
  top: -130%;
  z-index: -1;
  left: -65px;
}
@media (max-width: 1280px) {
  .main-feature {
    padding: 0px 75px;
  }
}
@media (max-width: 1024px) {
  .main-feature {
    padding: 0px 30px;
  }
}
@media (max-width: 678px) {
  .main-feature {
    padding: 0px 40px;
  }
  .feature {
    width: 90%;
  }
}
</style>
