<template>
  <div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-28 lg:px-8 relative">
    <h2 class="text-xl md:text-4xl font-bold md:leading-10 tracking-tight">
      {{ header }}
    </h2>
    <dl class="mt-10 space-y-8 divide-y divide-gray-900/10">
      <div
        v-for="(item, index) in faqs"
        :key="index"
        class="border-t border-ternGray"
      >
        <button
          @click="toggleAccordion(index)"
          class="w-full flex justify-between items-center py-4 md:py-6"
        >
          <span
            class="text-base md:text-lg leading-6 lg:col-span-5 text-left"
            >{{ item.question }}</span
          >
          <span class="ml-6 flex items-center text-ternGray">
            <svg
              class="h-6 w-6 transform"
              :class="{ 'rotate-180': item.isOpen }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </span>
        </button>
        <div v-show="item.isOpen" class="px-4 pt-2">
          <p class="text-base leading-7 text-gray-600">{{ item.answer }}</p>
        </div>
      </div>
    </dl>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: 'FAQ',
      header: 'Frequently Asked Questions',
      faqs: [
        {
          question: 'Is it free?',
          answer:
            'Yes! Tern is free to use. If you find value in our product, we kindly ask that you book your flights and accommodations through us, which helps us continue offering this service at no cost to you.',
          isOpen: false,
        },
        {
          question: 'How is Tern different from ChatGPT?',
          answer:
            "Tern offers a personalized and comprehensive travel planning solution. Upon signing up, Tern asks you five quick questions to tailor your recommendations. Each time you create an itinerary, our customized suggestions incorporate your preferences. The itinerary functions as an editable and shareable planner, enhanced with Google Search and Google Maps links. If you need more help, you can Ask AI and add the response directly into your itinerary. The planner also allows you to include tickets, flights, and hotel information, and share everything with a simple link. Tern's approach is specifically curated for travel, providing a more comprehensive itinerary that includes weather information, local transportation details, and reservation requirements. There's no smarter way to plan your next trip!",
          isOpen: false,
        },
        // {
        //   question: 'How does Tern connect me to a travel agent?',
        //   answer: `We have gathered a group of vetted independent travel agents who are excellent planners in their travel niches. We take into account what you are looking for and match you with the most appropriate travel agent in our network. We do not charge a fee for connecting you with an agent. Once connected, you can assess for yourself if you'd like to continue with the suggested agent. Please note, once we hand you off to the agent, Tern is not liable for any bookings, suggestions or interactions you may have with the travel agent.`,
        //   isOpen: false,
        // },
      ],
    };
  },
  methods: {
    toggleAccordion(index) {
      this.faqs[index].isOpen = !this.faqs[index].isOpen;
    },
  },
};
</script>

<style>
.rotate-180 {
  transform: rotate(180deg);
}
</style>
